<template>
  <div class="content ">


    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
      :style="{ borderTop: `2px solid ${primaryColor}` }"
    >

      <section class="text-center col-lg-8 col-sm-12">


        <div
          class="centered"
          style="width: 100%; "
          :style="{
              background: `linear-gradient(${secondaryColor}, black)`,
            }">


          <img
            v-lazy="
              article.metadata.cover.imgix_url +
              '?w=315&h=200&fit=fillmax&fill=blur&auto=format'
            "
            style="
              margin-top: -10px;
              opacity: 0.8;
              z-index: 12;
              box-shadow: 0px 10px 15px 0px rgb(0 0 0.6);
              border-radius: 0;
            "
            v-bind:alt="article.title"
            width="315px"
            height="200px"
            class=" d-inline-xs d-sm-none"

          />

          <img
            v-lazy="
              article.metadata.cover.imgix_url +
              '?w=615&h=400&fit=fillmax&fill=blur'
            "
            style="
              margin-top: -10px;
              opacity: 0.8;
              z-index: 12;
              box-shadow: 0px 10px 15px 0px rgb(0 0 0.6 );
              border-radius: 0;
            "
            v-bind:alt="article.title"
            width="615px"
            class=" d-none d-sm-inline"
            height="300px"
          />
          <h1
            class="tulum-party-title text-left"
            style="
             line-height: 1.2;
             text-transform: capitalize !important;
                margin: 20px;
                font-size: 1.8rem;
                padding: 20px;
              "
          >
            {{ article.title }}
          </h1>

          <p class="tulum-party-title text-left"
             style="font-size: 0.8rem; margin: 20px;  padding: 20px;margin-top:-60px;font-weight: normal;text-transform:uppercase">
            {{ article.metadata.caption }}

          </p>

        </div>

        <hr>

        <section
          class="text-left"
          style="
            color: #cacaca !important;
            margin-bottom: 1px;
            margin-top: 3px;
            padding-right: 20px;
            padding-left: 20px;
          "
        >
          <div class="card "
               style="border-bottom: 1px solid aquamarine"
          >
            <div class="card" >
              <small><i class="tim-icons  icon-watch-time"
                        :style="{color: secondaryColor, borderRadius: '100px', padding: '5px'}"></i>
                READING TIME: {{readingTime}}</small>
              <small><i class="tim-icons icon-calendar-60"
                        :style="{color: secondaryColor, borderRadius: '100px', padding: '5px'}"></i>
                CREATED: {{new Date(article.created_at).toDateString() }}</small>
              <small><i class="tim-icons icon-pencil"
                        :style="{color: secondaryColor, borderRadius: '100px', padding: '5px'}"></i>
                LAST MODIFIED: {{new Date(article.published_at).toDateString() }} </small>
            </div>

            <h5 class="section-header">
              <i class="tim-icons icon-light-3"
                 style="font-size: 12px"
                 :style="{color: secondaryColor, border: `2px solid ${primaryColor}`, borderRadius: '100px', padding: '5px'}"></i>
              SUMMARY</h5>
            <div class="text-left" v-html="article.metadata.summary" style="padding: 10px" ></div>



          </div>
          <div v-html="this.articleContent"></div>
<!--          <v-runtime-template :template="this.articleContent"></v-runtime-template>-->
          <div v-if="this.$route.path === '/article/afterlife-tulum'">
            <div class="row">
              <div class="col-sm-12">
                <ImgCard image="https://imgix.cosmicjs.com/90f647b0-f843-11ed-9363-e19d07f83187-afterlifelogo.png"
                         height="160" width="160"
                         text="<p><strong>What is now a worldwide phenomenon started with a profound passion and love for music.</strong> A shared vision for a curated soundscape and a particular approach was, and still is the goal of the label.</p>"
                         align="center" title="AFTERLIFE"></ImgCard>
              </div>
              <h3 class="section-header">AFTERLIFE TULUM THROUGH THE YEARS</h3>
              <div class="col-sm-12">
                <h3 class="section-header">2017</h3>

                <DynamicCard slug="card-afterlife-2017"></DynamicCard>
              </div>
              <div class="col-sm-12">
                <h3 class="section-header">2018</h3>

                <DynamicCard slug="card-afterlife-2018"></DynamicCard>
              </div>
              <div class="col-sm-12">
                <h3 class="section-header">2019</h3>
                <DynamicCard slug="card-afterlife-2019"></DynamicCard>
              </div>
              <div class="col-sm-12">
                <h3 class="section-header">2020</h3>
                <DynamicCard slug="card-afterlife-2020"></DynamicCard>
              </div>
              <div class="col-sm-12">
                <h3 class="section-header">2021</h3>
                <DynamicCard slug="card-afterlife-2021"></DynamicCard>
              </div>
              <div class="col-sm-12">
                <h3 class="section-header">2022</h3>
                <DynamicCard slug="card-afterlife-2022"></DynamicCard>
              </div>
              <div class="col-sm-12">
                <h3 class="section-header">2023</h3>
                <DynamicCard slug="card-afterlife-2023"></DynamicCard>
              </div>
              <div class="col-sm-12">
                <h3 class="section-header">2024</h3>
                <DynamicCard slug="card-afterlife-2024"></DynamicCard>
              </div>
              <div class="col-sm-12">
                <h3 class="section-header">2025</h3>
                <DynamicCard slug="card-afterlife-2025"></DynamicCard>
              </div>
            </div>

          </div>
          <div class="card centered text-center">
            <img v-lazy="bikeImg+'?format=auto&h=60&w=80'" height="60px" width="80px"
                 alt="tulum is a bik town"
                 class="centered">
            <img
              v-lazy="followThatDreamImg+'?w=120&h=30&fit=clamp'"
              height="30"
              alt="follow that dream"
              width="120"
              class="centered"
            />
            <small style="margin-top: 10px;">TULUM, MX</small>
          </div>
          <SmartMenu :links="links" :logo="this.instagramTp" top-section-name="HAVE A QUESTION"
                     :primary-color="primaryColor" :withRsvp="false">
            <div class="row">
              <div class="col-sm-12 text-center card">

                <img v-lazy="this.astroImg" height="50px" width="50px" class="centered">
                <p><strong>Feeling Lost?</strong></p>
                <p>
                  We know the scene inside out. Let's curate your perfect night.
                </p>
              </div>
            </div>
          </SmartMenu>

        </section>
      </section>
      <aside class="card col-sm-4 col-lg-4 d-none d-lg-inline">
        <SmartMenu :links="links" :logo="this.instagramTp" :primary-color="primaryColor">
          <p><strong>Feeling Lost?</strong></p>
          <hr>
          <p>
            We know the scene inside out. Let's curate your perfect night.
          </p>
          <hr>
        </SmartMenu>
      </aside>
    </article>
  </div>
</template>
<script>
import {mapState} from "vuex";
import VRuntimeTemplate from "v-runtime-template";
import ImgCard from '@/components/Cards/ImgCard'
import VideoCard from '@/components/Cards/VideoCard'
import DynamicCard from "@/components/Cards/DynamicCard";
import Carrousel from "@/components/Carrousel";
import DynamicLinks from "@/components/DynamicLinks";
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import MiniCarrousel from "@/components/MiniCarrousel";

export default {
  name: 'Article',
  props: ['slug'],
  components: {
    MiniCarrousel,
    mapState, VRuntimeTemplate, ImgCard, DynamicLinks, DynamicCard, VideoCard, Carrousel, SmartMenu},


  metaInfo() {
    return {
      title: `${this.article.title}. ${this.article.metadata.caption}`,
      // link: eventImage,
      meta: [
        {
          name: 'description',
          content: `${this.article.title}`,
        },
        {
          name: 'keywords',
          content: `Tulum, afterlife, parties, reviews, blog, articles`,
        },
        {
          property: 'og:title',
          content: `${this.article.title}. ${this.article.metadata.caption}`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/article/${this.slug}`,
        },
        {
          property: 'og:description',
          content: `${this.article.title}`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content: this.currentArticleImage,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('getArticle', {slug: this.slug});
    this.$store.dispatch('getLinks', {slug: 'article'});
    this.photos = 'article-general-images';
  },
  data: () => {
    return {
      primaryColor: 'rgb(118 153 142)',
      secondaryColor: '#7a8382',
      instagramImage: 'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png',
      sanDj: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      instagramTp:
        'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      bikeImg: 'https://imgix.cosmicjs.com/9562a300-47ef-11ef-a504-63e081e4680f-animated-bike.webp',
      followThatDreamImg:
        'https://imgix.cosmicjs.com/89de8e60-9a96-11ee-b34c-99d1d89e42eb-IMG5765.jpg',
    };
  },

  computed:
    mapState({
      articleContent: (state) => {
        return `<div>${state.article.content}</div>`
      },
      article: (state) => state.article,
      links: (state) => state.links || [],
      currentArticleImage: (state) =>
        state.article.metadata.cover &&
        state.article.metadata.cover.imgix_url,
      readingTime: (state) => {
        const wpm = 225;
        const words = state.article.content.trim().split(/\s+/).length;
         return Math.ceil(words / wpm) + ' mins';
      }},
    )
}
</script>


